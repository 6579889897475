<template>
    <div class="mainDiv">
        <img src="../../assets/logout_white.png" class="disconnect" @click="logOut" v-tippy="{ placement: 'right', content: t('Logout') }" />
        <template v-if="nextStep == false">
            <div class="flexBox">
                <div style="margin-top: 20px;">
                    <h2 class="title">Stop wasting time <span style="background-color: #c198f6;">editing</span> your <span style="background-color: var(--color2); color: white;">videos</span></h2>
                    <h1 class="title">Welcome to {{ domain_customization.serviceName }} !</h1>
    
                </div>
                <Player id="player" src="https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/yopbox_2024.mp4" :controls="['play', 'play-large', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']" />
                <button class="button getStarted" @click="nextStep = true">Get Started 🎬</button>
            </div>
        </template>

        <template v-else>
            <h2 class="title" style="margin: 40px 0 20px 0; font-size: 30px; font-weight: 700;">Choose your plan</h2>
            <!-- PLANS -->
            <div class="plans center">

                <!-- Plan Starter -->
                <planTemplate plan="starter" :mode="planMode">
                    <template v-slot:title>
                        <h2>Starter</h2>
                    </template>
                    <template v-slot:features>
                        <p>2 {{ t('Profile.subscription.plans.identity') }}</p>
                        <p>{{ t('Profile.subscription.plans.sound_enhancement') }}</p>
                        <p>{{ t('Profile.subscription.plans.auto_silences_remove') }}</p>
                        <p>{{ t('Profile.subscription.plans.ai_transcription') }}</p>
                        <p>{{ t('Profile.subscription.plans.subtitles') }}</p>
                        <p>{{ t('Profile.subscription.plans.screen_record') }}</p>
                        <p>{{ t('Profile.subscription.plans.formats') }}</p>
                        <p>{{ t('Profile.subscription.plans.no_yopbox_watermark') }}</p>
                        <p>{{ t('Profile.subscription.plans.live_chat') }}</p>
                    </template>
                </planTemplate>


                <!-- Plan Pro -->
                <planTemplate plan="pro" :mode="planMode">
                    <template v-slot:title>
                        <h2>Pro</h2>
                    </template>
                    <template v-slot:features>
                        <p>5 {{ t('Profile.subscription.plans.identity') }}</p>
                        <p>{{ t('Profile.subscription.plans.sound_enhancement') }}</p>
                        <p>{{ t('Profile.subscription.plans.auto_silences_remove') }}</p>
                        <p>{{ t('Profile.subscription.plans.ai_transcription') }}</p>
                        <p>{{ t('Profile.subscription.plans.subtitles') }}</p>
                        <p>{{ t('Profile.subscription.plans.screen_record') }}</p>
                        <p>{{ t('Profile.subscription.plans.formats') }}</p>
                        <p>{{ t('Profile.subscription.plans.no_yopbox_watermark') }}</p>
                        <p>{{ t('Profile.subscription.plans.live_chat') }}</p>
                    </template>
                </planTemplate>

                <!-- Plan Pro -->
                <planTemplate plan="unlimited" :mode="planMode">
                    <template v-slot:title>
                        <h2>Unlimited</h2>
                    </template>
                    <template v-slot:features>
                        <p>{{ t('Profile.subscription.plans.identity', { unlimited: "illimitées" }) }}</p>
                        <p>{{ t('Profile.subscription.plans.sound_enhancement') }}</p>
                        <p>{{ t('Profile.subscription.plans.auto_silences_remove') }}</p>
                        <p>{{ t('Profile.subscription.plans.ai_transcription') }}</p>
                        <p>{{ t('Profile.subscription.plans.subtitles') }}</p>
                        <p>{{ t('Profile.subscription.plans.screen_record') }}</p>
                        <p>{{ t('Profile.subscription.plans.formats') }}</p>
                        <p>{{ t('Profile.subscription.plans.no_yopbox_watermark') }}</p>
                        <p>{{ t('Profile.subscription.plans.live_chat_plus') }}</p>
                        <p>{{ t('Profile.subscription.plans.team_features') }}</p>
                    </template>
                </planTemplate>

            </div>

            <h3 class="watchVideo" @click="nextStep = false">⬅ Watch the video</h3>

        </template>
    </div>
</template>


<script setup>
import { inject, ref, defineAsyncComponent } from "vue"
import domain_customization_global from "../../domain_customization"

const domain_customization = domain_customization_global[process.env.domain_name];

import * as VueI18n from "vue-i18n";

const { t } = VueI18n.useI18n();

const nextStep = ref(false);

const planMode = "monthly"

const planTemplate = defineAsyncComponent(()=>import('../Profile/subViews/subscriptions/plans/plan_template.vue'))

import { useStore } from "vuex";
const store = useStore();

import { useCookies } from "vue3-cookies";
import { useRouter } from 'vue-router'
const router = useRouter()
const { cookies } = useCookies();

function logOut(){
    store.dispatch('clean_Token_And_Usr_Info');
    cookies.remove("token", null, process.env.TOKEN_COOKIE_DOMAIN);
    router.push({ name: "Login" });
}

</script>



<style src="./style.scss" lang="scss" scopped></style>